import { EditTwoTone, FilterFilled, LeftSquareFilled, ReloadOutlined, RightSquareFilled, WarningFilled } from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Form,
  Image,
  Pagination,
  Radio,
  Row,
  Slider,
  Spin,
  Switch,
  Table,
  Tabs,
  Tooltip,
  Dropdown,
  Space,
  Modal,Input,Upload,Select
} from "antd";
const { Dragger } = Upload;
import axios from "axios";
import { CloudUploadOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState, version } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import filterIconBlue from "../../assets/img/filterIconBlue.svg";
import leftArrow from "../../assets/img/leftArrow.svg";
import noDataIcon from "../../assets/img/noDataIcon.svg";
import uploadIcon from "../../assets/img/uploadIcon.svg";

import {
  actionGetProjectsDetailsComponents,
  actionGetProjectsDetailsIssues,
  actionGetProjectsDetailsServicesEOL,
  actionGetProjectsDetailsMetrics,
} from "../../store/services/projectService";
import SortIcon from "../common/SortIcon";
import { getCreatedOn } from "../helper/dateHelper";
import { isVersionGreater } from "../helper/common";
import { useTheme } from "../common/ThemeContext";
import { BASE_URL } from "../../config/web-config";

const ProjectDetail = () => {
  const [componentRiskScore, setComponentRiskScore] = useState(500);
  const [issuesRiskScore, setIssuesRiskScore] = useState(500);
  const [currentPageForComponent, setCurrentPageForComponent] = useState(1);
  const [itemsPerPageForComponent, setItemsPerPageForComponent] = useState(10);
  const [currentPageForIssues, setCurrentPageForIssues] = useState(1);
  const [itemsPerPageForIssues, setItemsPerPageForIssues] = useState(10);
  const [currentPageForServicesEOL, setCurrentPageForServicesEOL] = useState(1);
  const [itemsPerPageForServicesEOL, setItemsPerPageForServicesEOL] =
    useState(10);
  const [selectedSeverityComponent, setSelectedSeverityComponent] =
    useState("");
  const [selectedSeverityIssues, setSelectedSeverityIssues] = useState("");
  const [componentOutdatedVersion, setComponentOutdatedVersion] =
    useState(false);
  const [issuesOutdatedVersion, setIssuesOutdatedVersion] = useState(false);


  const [hasVulnerabilitiesComp, sethasVulnerabilitiesComp] = useState(false);
  const [directdepenciesComp, setdirectdepenciesComp] = useState(false);

  const [componentfilterVisible,setcomponentfilterVisible] = useState(false)
  const [issuesfilterVisible,setissuesfilterVisible] = useState(false)

  const controlFilterFortabs = (type) => {
    if(type == '2'){
      setcomponentfilterVisible(false)
      setissuesfilterVisible(!issuesfilterVisible)
    }else{
      setissuesfilterVisible(false)
      setcomponentfilterVisible(!componentfilterVisible)
    }
  }

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location || {};
  const { projectId, projectName } = state || {}

  const activeTab = location.state?.tab || "3";

  const { isDarkMode } = useTheme();

  const {
    getProjectsDetailsIssuesLoader,
    projectsDetailsIssues,
    getProjectsDetailsComponentsLoader,
    projectsDetailsComponents,
    totalProjectsComponent,
    totalProjectsIssues,
    projectsDetailsServicesEOL,
    totalProjectsServicesEOL,
    getProjectsDetailsServicesEOLLoader,
    actionGetProjectsDetailsMetricsData,
    AddProjectmodalDisplayNamesData,
    addProjectLoader,
  } = useSelector((state) => state.project);
  const { components, severityCount } = projectsDetailsComponents || {};
  const { projects } = projectsDetailsIssues || {};
  const dispatch = useDispatch();

  const customEmpty = (
    <div className="flex flex-col items-center">
      <img src={noDataIcon} alt="No Data" />
      <p className="text-[#393939] text-[15px] font-montserratMedium">
        No Data Found
      </p>
    </div>
  );

  const onChangeComponentSeverity = (e) => {
    setSelectedSeverityComponent(e.target.value);
  };
  const onChangeIssuesSeverity = (e) => {
    setSelectedSeverityIssues(e.target.value);
  };

  const onChangeOutdatedComponentVersion = (e) => {
    setComponentOutdatedVersion(e);
  };
  const onChangeOutdatedIssuesVersion = (e) => {
    setIssuesOutdatedVersion(e);
  };

  const handleResetComponentData = () => {
    setSelectedSeverityComponent("");
    setComponentOutdatedVersion(false);
    sethasVulnerabilitiesComp(false);
    setdirectdepenciesComp(false);
  };
  const handleResetIssusData = () => {
    setSelectedSeverityIssues("");
    setIssuesOutdatedVersion(false);
  };

  useEffect(()=>{
    projectId &&
    dispatch(
      actionGetProjectsDetailsMetrics({
        id: projectId,
      })
    )
  },[])

  useEffect(() => {
    projectId &&
      dispatch(
        actionGetProjectsDetailsComponents({
          id: projectId,
          currentPageForComponent,
          itemsPerPageForComponent,
          selectedSeverity: selectedSeverityComponent,
          onlyOutdatedComponents: componentOutdatedVersion,
          directdepenciesComp:directdepenciesComp,
          hasVulnerabilitiesComp:hasVulnerabilitiesComp
        })
      );
  }, [
    currentPageForComponent,
    itemsPerPageForComponent,
    selectedSeverityComponent,
    componentOutdatedVersion,
    directdepenciesComp,
    hasVulnerabilitiesComp
  ]);

  useEffect(() => {
    projectId &&
      dispatch(
        actionGetProjectsDetailsIssues({
          id: projectId,
          currentPageForIssues,
          itemsPerPageForIssues,
          selectedSeverity: selectedSeverityIssues,
          // onlyOutdatedComponents: issuesOutdatedVersion,
        })
      );
  }, [
    state,
    currentPageForIssues,
    itemsPerPageForIssues,
    selectedSeverityIssues,
    issuesOutdatedVersion,
  ]);

  useEffect(() => {
    projectId &&
      dispatch(
        actionGetProjectsDetailsServicesEOL({
          id: projectId,
          currentPageForServicesEOL,
          itemsPerPageForServicesEOL,
        })
      );
  }, [state, currentPageForServicesEOL, itemsPerPageForServicesEOL]);

  const componentColumns = [
    {
      title: "Components",
      dataIndex: "name",
      fixed: "left",
      width: 200,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Version",
      width: 120,
      dataIndex: "version",
      // render: (text) => <span className="ml-2">{text || "-"}</span>,
      render(text,record) {
        let versioninfo = isVersionGreater(record?.version,record?.latestVersion);

        const cellRef = useRef(null);

        useEffect(() => {
          if (cellRef.current) {
            const closestTd = cellRef.current.closest('td');
            if (closestTd) closestTd.style.padding = '16px 6px 16px 0px';
          }
        }, [versioninfo]);

        return {
          children: <div 
          ref={cellRef}
          className={versioninfo ? 'versioCheck':''}>
            <span className="ml-2">{text || "-"}</span>
            {versioninfo &&  <Tooltip title={(
              <>
              <span>Risk : Outdated Component.</span><br/>
              <span>Current version is: {record?.latestVersion}</span>
              </>
            )}> <WarningFilled style={{color:isDarkMode ? "#FFFF00" : "#FF0000"}} /></Tooltip>}
          </div>
        };
      } 
    },
    {
      title: "Group",
      width: 200,
      dataIndex: "group",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    // {
    //   title: "Internal",
    //   width: 100,
    //   dataIndex: "internal",
    //   render: (text) => <span className="ml-2">{text || "-"}</span>,
    // },
    {
      title: "License",
      width: 150,
      dataIndex: "license",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    // {
    //   title: "Vulnerability",
    //   width: 100,
    //   align: "center",
    //   dataIndex: "Vulnerability",
    //   render: (text) => <span className="ml-2">{text || "-"}</span>,
    // },
    {
      title: "Risk Score",
      width: 80,
      sortIcon: () => <SortIcon />,
      dataIndex: "lastInheritedRiskScore",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
  ];
  const issuesColumns = [
    {
      title: "Components",
      dataIndex: "component",
      fixed: "left",
      width: 200,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text?.name || "-"}</span>,
    },
    {
      title: "Version",
      width: 100,
      dataIndex: "component",
      render: (text) => <span className="ml-2">{text?.version || "-"}</span>,
    },
    {
      title: "Group",
      width: 200,
      dataIndex: "component",
      render: (text) => <span className="ml-2">{text?.group || "-"}</span>,
    },
    {
      title: "Vulnerability",
      width: 250,
      ellipsis:true,
      dataIndex: "vulnerability",
      render: (text) => <span className="ml-2">{text?.vulnId || "-"}</span>,
    },
    {
      title: "Severity",
      width: 150,
      dataIndex: "vulnerability",
      render: (text) => <span className="ml-2">{text?.severity || "-"}</span>,
    },
    {
      title: "Analyzer",
      width: 250,
      ellipsis:true,
      dataIndex: "attribution",
      render: (text) => <span className="ml-2">{text?.analyzerIdentity || "-"}</span>,
    },
    {
      title: "Attributed On",
      width: 200,
      ellipsis:true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      dataIndex: "attribution",
      render: (text) => <span className="ml-2">{text?.attributedOn || "-"}</span>,
    },
    {
      title: "Analysis",
      width: 250,
      ellipsis: true,
      dataIndex: "analysis",
      // render: (text) => <span className="ml-2">{text || "-"}</span>,
      render: (text) => <span className="ml-2">{"-"}</span>,
    },
    {
      title: "Suppressed",
      width: 150,
      dataIndex: "analysis",
      render: (text) => <span className="ml-2">{text?.isSuppressed ? "True" : "False"}</span>,
      // render: (text) => <span className="ml-2">{text?.isSuppressed || "-"}</span>,
    },
  ];
  const servicesEOLColumns = [
    {
      title: "Product",
      dataIndex: "productName",
      fixed: "left",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Current Release Version",
      dataIndex: "supportedReleaseVersion",
      render: (text) => <span className="ml-2">{text || "-"}</span>
    },
    {
      title: "Latest Release-Version",
      dataIndex: "latestReleaseVersion",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Active Support",
      dataIndex: "activeSupport",
      width: "25%",
      render(text,record) {
        return {
          props: {
            style: { padding:'2px'}
          },
          children: <div style={{
            padding:'14px 16px',
            background: record?.severity?.substring(record?.severity?.indexOf("#"), record?.severity?.indexOf(")"))
          }}><span className="ml-2">{text || "-"}</span></div>
        };
      }    
    },
    {
      title: "Severity",
      dataIndex: "severity",
      render: (text) => <span className="ml-2">{text?.substring(0, text?.indexOf("(")) || "-"}</span>
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 120,
      fixed: "right",
      render: (text, data) => (
        <>
          <div className="flex gap-x-2 items-center cursor-pointer justify-center">
            <EditTwoTone />
          </div>
        </>
      ),
    },
  ];

  const filterItemsComponent = [
    {
      key: "1",
      label: "Severity",
      children: (
        <div>
          <Radio.Group
            onChange={onChangeComponentSeverity}
            value={selectedSeverityComponent}
            className="flex flex-col gap-y-2"
          >
            <Radio
              value="critical"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              Critical
            </Radio>
            <Radio
              value="high"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              High
            </Radio>
            <Radio
              value="medium"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              Medium
            </Radio>
            <Radio
              value="low"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              Low
            </Radio>
          </Radio.Group>
        </div>
      ),
    },
    // {
    //   key: "2",
    //   label: "Risk Score",
    //   children: (
    //     <div>
    //       <p className="text-[13px] lg:text-[15px] text-filterOption-text font-montserratRegular">
    //         Score between 0-1000
    //       </p>
    //       <Slider
    //         min={0}
    //         max={1000}
    //         value={componentRiskScore}
    //         onChange={setComponentRiskScore}
    //       />
    //     </div>
    //   ),
    // },
    {
      key: "3",
      label: "Outdated Version",
      children: (
        <div>
          <Switch
            value={componentOutdatedVersion}
            onChange={onChangeOutdatedComponentVersion}
          />
        </div>
      ),
    },
    {
      key: "4",
      label: "Direct Dependencies",
      children: (
        <div>
          <Switch
            value={directdepenciesComp}
            onChange={()=> setdirectdepenciesComp(!directdepenciesComp)}
          />
        </div>
      ),
    },
    {
      key: "5",
      label: "Has Vulnerabilities",
      children: (
        <div>
          <Switch
           value={hasVulnerabilitiesComp}
           onChange={()=> sethasVulnerabilitiesComp(!hasVulnerabilitiesComp)}
          />
        </div>
      ),
    },
    
  ];
  const filterItemsIssues = [
    {
      key: "1",
      label: "Severity",
      children: (
        <div>
          <Radio.Group
            onChange={onChangeIssuesSeverity}
            value={selectedSeverityIssues}
            className="flex flex-col gap-y-2"
          >
            <Radio
              value="critical"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              Critical
            </Radio>
            <Radio
              value="high"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              High
            </Radio>
            <Radio
              value="medium"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              Medium
            </Radio>
            <Radio
              value="low"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              Low
            </Radio>
          </Radio.Group>
        </div>
      ),
    },
    // {
    //   key: "2",
    //   label: "Risk Score",
    //   children: (
    //     <div>
    //       <p className="text-[13px] lg:text-[15px] text-filterOption-text font-montserratRegular">
    //         Score between 0-1000
    //       </p>
    //       <Slider
    //         min={0}
    //         max={1000}
    //         value={issuesRiskScore}
    //         onChange={setIssuesRiskScore}
    //       />
    //     </div>
    //   ),
    // },
    // {
    //   key: "3",
    //   label: "Outdated Version",
    //   children: (
    //     <div>
    //       <Switch
    //         value={issuesOutdatedVersion}
    //         onChange={onChangeOutdatedIssuesVersion}
    //       />
    //     </div>
    //   ),
    // },
    // {
    //   key: "3",
    //   label: "‘Fixed in’ Available",
    //   children: (
    //     <div>
    //       <Checkbox.Group onChange={onChange} className="flex flex-col gap-y-2">
    //         <Checkbox
    //           value="yes"
    //           className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
    //         >
    //           Yes
    //         </Checkbox>
    //         <Checkbox
    //           value="no"
    //           className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
    //         >
    //           No
    //         </Checkbox>
    //       </Checkbox.Group>
    //     </div>
    //   ),
    // },
    // {
    //   key: "4",
    //   label: "Computed Fixability",
    //   children: (
    //     <div>
    //       <Checkbox.Group onChange={onChange} className="flex flex-col gap-y-2">
    //         <Checkbox
    //           value="fixable"
    //           className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
    //         >
    //           Fixable
    //         </Checkbox>
    //         <Checkbox
    //           value="partially_fixable"
    //           className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
    //         >
    //           Partially Fixable
    //         </Checkbox>
    //       </Checkbox.Group>
    //     </div>
    //   ),
    // },
  ];

  const tabsItems = [
    {
      key: "3",
      label: "Services - End of Life",
      children: (
        <>
          <div className="w-full">
            <div className="text-end mb-2 lg:mb-4">
              <Button
                onClick={() => {
                  navigate("/add-service", {
                    state: { projectId: projectId, projectName: projectName },
                  });
                }}
                type="primary"
                className="w-[150px] h-[45px] text-[12px] lg:text-[15px] font-montserratMedium"
              >
                Add Service
              </Button>
            </div>
            <Table
            bordered
              // rowKey={(record, index) => index}
              rowKey="id"
              pagination={false}
              scroll={{
                x: "90vh",
                // y: window.innerWidth < 1500 ? "49vh" : "61vh",
              }}
              className="bg-transparent gray-scrollbar"
              columns={servicesEOLColumns}
              dataSource={projectsDetailsServicesEOL}
              // loading={getProjectsDetailsIssuesLoader}
              locale={{
                emptyText: customEmpty, // Apply the custom empty state
              }}
            />
            <div className="flex justify-end mt-2">
              <Pagination
                hideOnSinglePage
                current={currentPageForServicesEOL}
                pageSize={itemsPerPageForServicesEOL}
                total={totalProjectsServicesEOL}
                onChange={(page, pageSize) => {
                  setCurrentPageForServicesEOL(page); // Update current page
                  setItemsPerPageForServicesEOL(pageSize); // Optionally, handle items per page change
                }}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      key: "1",
      label: "Component",
      children: (
        <div className="">
          {/* guna */}
           {/* {!componentfilterVisible &&
          <div className="cursor-pointer w-[2%] lg:w-[3%] border border-border bg-box-secondary p-2" onClick={()=>{setcomponentfilterVisible(!componentfilterVisible)}}>
           <RightSquareFilled style={{ fontSize: '20px' }} />
          </div>
          }
          {componentfilterVisible &&
          <div className="w-[25%] lg:w-[20%] border border-border bg-box-secondary p-2 lg:p-4">
            <div className="flex gap-x-3 items-center justify-between">
              <div className="flex items-center gap-x-2">
                <img src={filterIconBlue} alt="filter" />
                <p className="text-[13px] lg:text-[15px] font-montserratMedium text-text-primary">
                  Filter
                </p>
              </div>
              <div>
                <Tooltip title="Reset">
                  <span
                    className="cursor-pointer"
                    onClick={handleResetComponentData}
                  >
                    <ReloadOutlined
                      style={{ color: "#1778f2", fontWeight: 600 }}
                    />
                  </span>
                </Tooltip>

                <Tooltip title="Collapse">
                  <span
                    className="cursor-pointer pl-2"
                    onClick={()=>{setcomponentfilterVisible(!componentfilterVisible)}}                  >
                    <LeftSquareFilled  style={{ fontSize: '20px' , fontWeight: 800 }}  />
                  </span>
                </Tooltip>
              </div>
            </div>
            <div className="mt-2 lg:mt-3">
              <Collapse
                defaultActiveKey={["1", "3", "4","5"]}
                ghost
                collapsible="icon"
                items={filterItemsComponent}
                bordered={false}
              />
            </div>
          </div>
          } */}
          <div className={`w-[100%] lg:w-[100%] ptop-2`}>
            <Table
              bordered
              // rowKey={(record) => record.name}
              rowKey="id"
              pagination={false}
              scroll={{ x: "90vh" }}
              className="bg-transparent"
              columns={componentColumns}
              dataSource={components}
              // loading={getProjectsDetailsComponentsLoader}
              locale={{
                emptyText: customEmpty, // Apply the custom empty state
              }}
            />
            <div className="flex justify-end mt-2">
              <Pagination
                hideOnSinglePage
                current={currentPageForComponent}
                pageSize={itemsPerPageForComponent}
                total={totalProjectsComponent}
                onChange={(page, pageSize) => {
                  setCurrentPageForComponent(page); // Update current page
                  setItemsPerPageForComponent(pageSize); // Optionally, handle items per page change
                }}
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Issues",
      children: (
        <div className="">
          {/* p-4 lg:p-8 */}
          <div className="w-[100%] lg:w-[100%] ptop-2">
            <Table
            bordered
              // rowKey={(record) => record.name}
              rowKey="id"
              pagination={false}
              scroll={{
                x: "90vh",
                // y: window.innerWidth < 1500 ? "49vh" : "61vh",
              }}
              className="bg-transparent gray-scrollbar"
              columns={issuesColumns}
              dataSource={projects}
              // loading={getProjectsDetailsIssuesLoader}
              locale={{
                emptyText: customEmpty, // Apply the custom empty state
              }}
            />
            <div className="flex justify-end mt-2">
              <Pagination
                hideOnSinglePage
                current={currentPageForIssues}
                pageSize={itemsPerPageForIssues}
                total={totalProjectsIssues}
                onChange={(page, pageSize) => {
                  setCurrentPageForIssues(page); // Update current page
                  setItemsPerPageForIssues(pageSize); // Optionally, handle items per page change
                }}
              />
            </div>
          </div>
        </div>
      ),
    },
  ];

  const [currentTab,setCurrentTab] = useState("3")
  const onChangeTabs = (key) => {
    setCurrentTab(key)
    setcomponentfilterVisible(false)  
    setissuesfilterVisible(false)
  };

  const downloadReport = async () => {
    try {
      const response = await fetch(`${BASE_URL}project/${projectId}/excel`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Bearer " + localStorage.getItem("token"),
          // Include any other headers your API requires
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'filename.xlsx'; // Set the desired file name and extension
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

 const items = [
    {
      label: (
      <button onClick={()=>downloadReport()}>Download Excel File</button>
      ),
      key: '0',
    },
    {
      label: (<button>Download Excel</button>),
      key: '1',
    },
  ];

  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState(false);
  const [addProjectForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [sbomFile, setSbomFile] = useState(null);
  const [userData, setUserData] = useState([]);
  const [filetypeValue, setfiletypeValue] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);


  const addFileProps = {
    name: "file",
    multiple: false,
    listType: "picture",
    action: null,
    fileList: fileList,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },

    beforeUpload: (file) => {
      setSbomFile(file);
      // toast.success(`File selected: ${file.name}`, { duration: 5000 });
      return false;
    },
    onChange(info) {
      const { status, fileList } = info;

      if (status !== "uploading") {
        // Keep the fileList in its original form
        setFileList(fileList);
      }
      if (status === "done") {
        toast.success(`File uploaded successfully.`);
      } else if (status === "error") {
        toast.error(`File upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleAddProject = async (value) => {
    const req = {
      projectVo: {
        name: value.name,
        groupOrUserName: userData?.tenant,
        type: "Type A",
        version: value.version,
        description: value.description,
        // tagList: selectedItems,
        organizationUUID: orgId,
        userUUID: userData?.userId,
      },
      sbomFile,
      displayName : value?.displayName
    };

    await dispatch(
      actionAddProject({
        req,
        handleCancel: handleCancelAddProjectModal,
      })
    );

    dispatch(
      actionGetProjectsByOrgId({
        id: orgId,
        sort: sortingValue,
        currentPage,
        itemsPerPage,
      })
    );
  };

  const handleCancelAddProjectModal = () => {
    setIsAddProjectModalOpen(false);
    addProjectForm.resetFields();
    setFileList([]);
    setSbomFile(null);
  };

  return (
    <>
      {projectId ?
        <Spin
          spinning={
            getProjectsDetailsIssuesLoader ||
            getProjectsDetailsComponentsLoader ||
            getProjectsDetailsServicesEOLLoader
          }
        >
          <Row gutter={[16, 10]} justify="space-between">
            <Col xs={24} sm={24} md={24} lg={24} className="flex items-center">
              <div className="flex justify-between items-center w-full">
                  <div className="flex">
                      <div className="cursor-pointer mr-2" onClick={() => navigate(-1)}>
                    <Image src={leftArrow} alt="image" preview={false} />
                  </div>
                  <p>
                    <span className="text-[15px] font-montserratMedium text-[#757575]">
                      {actionGetProjectsDetailsMetricsData?.createdOn && <>Created {getCreatedOn(actionGetProjectsDetailsMetricsData?.createdOn)} |{" "}</>}
                    </span>
                    <span className="text-[15px] font-montserratMedium text-text-secondary">
                      Scan Status : {actionGetProjectsDetailsMetricsData?.scanStatus} {" "}
                    </span>
                  
                  </p>
                  </div>
                  <div className="flex ">
                  <Button className="mr-1" type="primary" onClick={()=>downloadReport()}>Download Excel</Button>
                  <Button className="mr-1" type="primary" icon={<CloudUploadOutlined />}
                   onClick={()=>setIsAddProjectModalOpen(true)}></Button>
                  <Dropdown menu={{ items }}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                    <Button type="primary" >Exports</Button>
                    </Space>
                  </a>
                </Dropdown>
                  </div>
                </div>
            </Col>
            <Col xs={24} sm={12} md={12} lg={4}>
              <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-red-border">
                <h1 className="font-montserratMedium text-[17px]">
                  {actionGetProjectsDetailsMetricsData?.metrics?.critical || "0"}
                </h1>
                <p className="pt-2 text-[15px]">Critical</p>
              </div>
            </Col>

            <Col xs={24} sm={12} md={12} lg={4}>
              <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-pink-border">
                <h1 className="font-montserratMedium text-[17px]">
                  {actionGetProjectsDetailsMetricsData?.metrics?.high || "0"}
                </h1>
                <p className="pt-2 text-[15px]">High</p>
              </div>
            </Col>

            <Col xs={24} sm={12} md={12} lg={4}>
              <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-blue-border">
                <h1 className="font-montserratMedium text-[17px]">
                  {actionGetProjectsDetailsMetricsData?.metrics?.medium || "0"}
                </h1>
                <p className="pt-2 text-[15px]">Medium</p>
              </div>
            </Col>

            <Col xs={24} sm={12} md={12} lg={4}>
              <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-green-border">
                <h1 className="font-montserratMedium text-[17px]">
                  {actionGetProjectsDetailsMetricsData?.metrics?.low || "0"}
                </h1>
                <p className="pt-2 text-[15px]">Low</p>
              </div>
            </Col>

            {/* <Col xs={24} sm={12} md={12} lg={4}>
              <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-grey-border">
                <h1 className="font-montserratMedium text-[17px]">
                  {actionGetProjectsDetailsMetricsData?.metrics?.unassigned || "0"}
                </h1>
                <p className="pt-2 text-[15px]">Unassigned</p>
              </div>
            </Col> */}

            <Col xs={24} sm={12} md={12} lg={4}>
              <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-orange-border">
                <h1 className="font-montserratMedium text-[17px]">
                {actionGetProjectsDetailsMetricsData?.metrics?.inheritRiskScore || "0"}
                </h1>
                <p className="pt-2 text-[15px]">Risk Score</p>
              </div>
            </Col>
          </Row>
          <div className="mt-2 md:mt-3 lg:mt-4">
            <Tabs
              className={`${currentTab != '3'? "extraControlclass":""}`}
              type="card"
              defaultActiveKey={activeTab}
              items={tabsItems}
              onChange={onChangeTabs}
              tabBarExtraContent={
                currentTab != "3" ? (
                <>
                <div className="relative">
                <Button className="mr-1" type="primary" onClick={()=>{controlFilterFortabs(currentTab)}} icon={<FilterFilled />}></Button> 
                {componentfilterVisible &&
                <div className="absolute filterContainer w-[25%] lg:w-[20%] border border-border bg-box-secondary p-2 lg:p-4">
                  <div className="flex gap-x-3 items-center justify-between">
                    <div className="flex items-center gap-x-2">
                      <img src={filterIconBlue} alt="filter" />
                      <p className="text-[13px] lg:text-[15px] font-montserratMedium text-text-primary">
                        Filter
                      </p>
                    </div>
                    <div>
                      <Tooltip title="Reset">
                        <span
                          className="cursor-pointer"
                          onClick={handleResetComponentData}
                        >
                          <ReloadOutlined
                            style={{ color: "#1778f2", fontWeight: 600 }}
                          />
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="mt-2 lg:mt-3">
                    <Collapse
                      defaultActiveKey={["1", "3", "4","5"]}
                      ghost
                      collapsible="icon"
                      items={filterItemsComponent}
                      bordered={false}
                    />
                  </div>
                </div>
                }
                {issuesfilterVisible &&
                  <div className="absolute filterContainer w-[25%] lg:w-[20%] border border-border bg-box-secondary p-2 lg:p-4">
                        <div className="flex gap-x-3 items-center justify-between">
                          <div className="flex items-center gap-x-2">
                            <img src={filterIconBlue} alt="filter" />
                            <p className="text-[13px] lg:text-[15px] font-montserratMedium text-text-primary">
                              Filter
                            </p>
                          </div>
                          <div>
                            <Tooltip title="Reset">
                              <span
                                className="cursor-pointer"
                                onClick={handleResetIssusData}
                              >
                                <ReloadOutlined
                                  style={{ color: "#1778f2", fontWeight: 600 }}
                                />
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="mt-2 lg:mt-3">
                          <Collapse
                            defaultActiveKey={["1","3"]}
                            ghost
                            collapsible="icon"
                            items={filterItemsIssues}
                            bordered={false}
                          />
                        </div>
                  </div>
                }
                </div>
              
                </>
              ):null            
            }
            />
          </div>
        </Spin>
        :
        <>
          <div className="flex flex-col items-center mt-10">
            <img src={noDataIcon} alt="No Data" />
            <p className="text-text-primary text-[15px] font-montserratMedium">
              Project details are not available
            </p>
            <div>
              <Button
                onClick={() => navigate("/project")}
                type="primary"
                className="delete-btn min-h-[40px] mt-2 lg:mt-4 cursor-pointer"
              >
                Back To Projects
              </Button>
            </div>
          </div>
        </>
      }


        <Modal
          centered
          destroyOnClose={handleCancelAddProjectModal}
          onOk={handleCancelAddProjectModal}
          onCancel={handleCancelAddProjectModal}
          footer={false}
          width={650}
          open={false}
        >
          <h1 className="text-text-primary text-[20px] lg:text-[23px] font-font-montserratRegular text-center">
            Modify Project
          </h1>
          <Form
            initialValues={{
              groupOrUserName: userData && userData.tenant,
              name:actionGetProjectsDetailsMetricsData?.name,
            }}
            form={addProjectForm}
            onFinish={handleAddProject}
            layout="vertical"
            className="mt-[20px] mb-0"
          >
            <Form.Item
              name="name"
              label="Name"
              className="mb-[12px]"
              size="medium"
              
              rules={[
                {
                  required: true,
                  message: "Please input project name!",
                },
              ]}
            >
              <Input
               disabled={true}
                style={{ background: "white" }}
                placeholder="Enter Project Name"
                className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
              />
            </Form.Item>
            <Form.Item
              name="version"
              label="Version"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: false,
                  message: "Please input the version!",
                },
              ]}
            >
              <Input
                disabled={true}
                style={{ background: "white" }}
                placeholder="Enter Version"
                className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
              />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: false,
                  message: "Please input the type!",
                },
              ]}
            >
              <Input.TextArea
                disabled={true}
                placeholder="Enter Description"
                className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
              />
            </Form.Item>
            <Form.Item
              name="sbomFile"
              label="Upload File"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: false,
                  message: "Please upload file!",
                },
              ]}
            >
              <Dragger
                {...addFileProps}
                onRemove={(file) => {
                  setSbomFile(null);
                  setFileList((prev) =>
                    prev.filter((item) => item.uid !== file.uid)
                  );
                }}
                accept=".json"
                maxCount={1}
              >
                <div className="flex justify-center gap-x-2 py-2 lg:py-4">
                  <img src={uploadIcon} alt="upload icon" />
                  <p className="text-text-primary text-[#808080] text-[14px] font-montserratRegular">
                    Upload File
                  </p>
                </div>
              </Dragger>
            </Form.Item>
            <Form.Item
              name="displayName"
              label="File Type"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: fileList?.length > 0 ? true : false,
                  message: "Please select file type",
                },
              ]}
            >

              <Select
                options={AddProjectmodalDisplayNamesData}
               className="remove-arrow tags-of-project"           
                style={{ width: "100%" }}
                placeholder="Select file type"
                value={filetypeValue}
                onChange={(value, selectedType) => {
                  setfiletypeValue(selectedType);
                }}
                notFoundContent={null}
                popupClassName="add-project-filtype-dropdown"
                allowClear
              />

            </Form.Item>
            <Form.Item
              label="Tags"
              name="tagList"
              rules={[
                {
                  required: false,
                  message: "Please input tags!",
                },
              ]}
            >
              <Select
                open={true}
                className="remove-arrow tags-of-project"
                popupClassName="tag-select"
                multiple={true}
                disabled={true}
                showSearch={false}
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Enter tags"
                value={selectedItems}
                // onChange={handleChangeTags}
                notFoundContent={null}
              />
            </Form.Item>

            <div className="flex justify-end mt-[40px]">
              <Form.Item className="mb-0">
                <button
                  onClick={() => handleCancelAddProjectModal()}
                  type="button"
                  className="rounded-[6px] text-grey-button border border-grey-button hover:text-grey-button min-h-[40px] min-w-[80px] text-[15px] font-montserratMedium"
                >
                  Cancel
                </button>
              </Form.Item>

              <div className="ml-4">
                <Form.Item className="mb-0">
                  <Button
                    loading={addProjectLoader}
                    type="primary"
                    className=" min-h-[40px] min-w-[120px] text-[15px] font-montserratMedium"
                    htmlType="submit"
                  >
                    Update Project
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </Modal>

    </>
  );
};

export default ProjectDetail;
